import React, {Component} from "react";
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class PillButton extends Component {

    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this);

        this.state = {
            onClick   : this.props.onClick === false ? null : this.props.onClick || this.onClick,
            text      : this.props.text || this.props.children,
            className : this.props.className || 'btn-xl pill-button',
            variant   : this.props.variant || 'dark',
            link      : this.props.link
        }
    }

    onClick(e) {
        e.preventDefault();
        try {
            window.location.href = this.props.link;
        }
        catch(e) {
            console.error(e)
        }
    }

    render() {
        let icon = '';
        if (this.props.icon) {
            icon = <FontAwesomeIcon size={'sm'} icon={this.props.icon} style={{marginRight: '10px'}}/>
        }
        return(
            <Button
                onClick={this.state.onClick}
                className={this.state.className}
                variant={this.state.variant}
                {...this.props}
            >
                {! this.props.iconPosition || this.props.iconPosition === 'before' ? <span className={'mr-2'}>{icon}</span> : '' }
                {this.state.text}
                {this.props.children}
                {this.props.iconPosition === 'after' ? <span className={'ml-2'}>{icon}</span> : '' }
            </Button>
        )
    }
}

export default PillButton;
