// React & Gatsby
import React from "react"
import Jumbotron from "react-bootstrap/Jumbotron";

// Custom
import Meta from "../Meta";
import Subscribe from "../Subscribe";
import Page from "../Layout/Page";
import MainMenu from "../MainMenu";

// Content & Style
import menuItems from "../../data/menu-items.json"
import "../../assets/scss/App.scss"


const BlankPage = ((props) => {
    return (
        <Page className={`blank-page full-width-path`}>
            <Meta/>
            <Jumbotron className={'bg-eefaeb mb-0 pb-0'}>
                <MainMenu menuItems={menuItems}/>
            </Jumbotron>

            {props.children}

            <Subscribe/>
        </Page>
    )
})

export default BlankPage;
