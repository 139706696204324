// React & Gatsby
import React from "react"
import Container from "react-bootstrap/Container";
// import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

// Custom
import PillButton from "../components/PillButton";
// import ArticlePage from "../components/Content/ArticlePage"
import BlankPage from "../components/Content/BlankPage"
import Story from "../components/Content/Story"

// Content & Styles
import "../assets/scss/App.scss"


const AboutPage = ((props) => {
    const viewAvatarsButton =
        <div className={'mb-4 mt-4'}>
            <PillButton
                link={'/products/ultimate-pack'}
                variant={'primary'}
                text={'Get the Avatars - $99'}
                icon={faShoppingCart}
                iconPosition={'after'}
            />
        </div>

    return (
        <React.Fragment>
            <BlankPage slug={'about'}>
                <Container fluid className={'p-0 m-0'} style={{backgroundColor: '#eefaeb'}}>
                    <Image src={'/images/hippie-chick-old.png'} style={{width: "50%"}} />
                    <Image src={'/images/hippie-chick-new.png'} style={{width: "50%"}} />
                    <p className={'text-center pb-5'}>
                        The style of the original avatars on the left compared to the current style.
                        Scott's wife was the inspiration for these particular avatars
                    </p>
                </Container>
                <Container>
                    <Row fluid className={'justify-content-center mt-4 mb-4'}>
                        <Story slug={'about'} />
                        {viewAvatarsButton}
                    </Row>
                </Container>
                <Container fluid className={'p-0 m-0'} style={{backgroundColor: '#eefaeb'}}>
                    <Image src={'/images/misc-people-old.png'} style={{width: "50%"}} />
                    <Image src={'/images/misc-avatars-transparent.png'} style={{width: "50%"}} />
                    <p className={'text-center pb-5'}>
                        (Left) Original ... less detailed, minimal, and less personality.
                        (Right) Evolution ... more detailed and more personable style of the finished avatars.
                    </p>
                </Container>
                <Container>
                    <Row fluid className={'justify-content-center mt-4 mb-4'}>
                        <Story slug={'design-process'} />
                        {viewAvatarsButton}
                    </Row>
                </Container>

                <Container fluid className={'p-0 m-0'}>
                    <Image src={'/images/Mike-jagger-avatar-icon.jpg'} style={{width: "100%"}}/>
                </Container>

                <Container>
                    <Row fluid className={'justify-content-center mt-4 mb-4'}>
                        <Story slug={'whats-next'} />
                        {viewAvatarsButton}
                    </Row>
                </Container>
            </BlankPage>
        </React.Fragment>
    )
})

export default AboutPage;
